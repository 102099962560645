import React from 'react';

const App = () => {
  return (
    <div>
      <h1 className="text-xl text-center font-bold">PT. AAKEN LOGISTICS INDONESIA - Your Forwarding Partner</h1>
    </div>
  )
}

export default App;